<template>
  <div>
    <button
      @click="ScreenShot"
      style="position: absolute; top: 50px; left: 20px; z-index: 999"
    >
      框选
    </button>
    <button
      @click="ZoomIn"
      style="position: absolute; top: 50px; left: 80px; z-index: 999"
    >
      放大
    </button>
    <button
      @click="ZoomOut"
      style="position: absolute; top: 50px; left: 140px; z-index: 999"
    >
      缩小
    </button>
    <input
      type="file"
      name="file"
      id="pdf"
      ref="pdf"
      style="position: absolute; top: 100px; left: 20px; z-index: 999"
      @change="PdfUrlChange"
    />
    <div id="viewerContainer">
      <div id="viewer" class="pdfViewer"></div>
    </div>
  </div>
</template>

<script>
import kscreenshot from "kscreenshot";
//import html2canvas from "html2canvas";

export default {
  name: "PDFJS",
  data() {
    var that = this;
    return {
      pdfUrl: "data/ddd.pdf",
      interval: null,
      pdfjsDistPath: ".",
      kScreenShot: new kscreenshot({
        //截图
        key: 65,
        needDownload: false,
        endCB(e) {
          //成功回调
          //console.log(e);
          that.Recognize(e);
        },
        cancelCB(e) {
          //失败回调
          console.log(e);
        },
      }),
      pdfViewer: null,
      keyBoard: "",
      scroll: "",
      currentScale: 0.28,
      abc: null,
    };
  },
  mounted: function () {
    //this.initPdf();
    // window.addEventListener('keydown',this.handleKeyDown)
    // window.addEventListener('keyup',this.handleKeyUp)
    // window.addEventListener('scroll',this.handleScroll)
  },
  destroyed() {
    // window.removeEventListener('keydown',this.handleKeyDown)
    // window.removeEventListener('keyup',this.handleKeyUp)
    // window.removeEventListener('scroll',this.handleScroll)
  },
  methods: {
    PdfUrlChange() {
      var that = this;
      let formData = new FormData();
      formData.append("pdf", this.$refs.pdf.files[0]);

      this.axios({
        //url: "http://127.0.0.1:5000/pdfupload",
        url: "http://paddle.zhongzhilian.com.cn/pdfupload",
        method: "post",
        data: formData,
      }).then((res) => {
        this.pdfUrl = "data/" + res.data;
        this.initPdf();
      });
    },
    initPdf() {
      var that = this;
      const CMAP_URL = "utils/pdf/cmaps/";

      const CMAP_PACKED = true;

      const DEFAULT_URL = this.pdfUrl;
      //const DEFAULT_URL = "data/ddd.pdf";

      const container = document.getElementById("viewerContainer");

      const eventBus = new pdfjsViewer.EventBus();

      eventBus.on("pagerendered", function () {
        that.renderWaterMarkDiv();
      });
      // (Optionally) enable hyperlinks within PDF files.
      const pdfLinkService = new pdfjsViewer.PDFLinkService({
        eventBus,
      });

      this.pdfViewer = new pdfjsViewer.PDFViewer({
        container,
        eventBus,
        linkService: pdfLinkService,
        renderer: "svg",
        textLayerMode: 0,
      });
      pdfLinkService.setViewer(this.pdfViewer);

      eventBus.on("pagesinit", function () {
        // We can use pdfViewer now, e.g. let's change default scale.
        //that.pdfViewer.currentScaleValue = "page-width";
        that.pdfViewer.currentScaleValue = that.currentScale;
      });

      // Loading document.
      const loadingTask = pdfjsLib.getDocument({
        url: DEFAULT_URL,
        cMapUrl: CMAP_URL,
        cMapPacked: CMAP_PACKED,
      });

      loadingTask.promise.then(function (pdfDocument) {
        // Document loaded, specifying document for the viewer and
        // the (optional) linkService.
        that.pdfViewer.setDocument(pdfDocument);

        pdfLinkService.setDocument(pdfDocument, null);
      });
    },
    handleKeyDown(e) {
      if (e.code == "AltLeft") {
        this.keyBoard = "down";
      }
    },
    handleKeyUp(e) {
      if (e.code == "AltLeft") {
        this.keyBoard = "up";
      }
    },
    handleScroll() {
      this.scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      console.log(this.scroll);
    },
    ZoomIn() {
      this.currentScale += 0.1;
      this.pdfViewer.currentScaleValue = this.currentScale;
      this.renderWaterMarkDiv();
    },
    ZoomOut() {
      if (this.currentScale - 0.1 > 0) {
        this.currentScale -= 0.1;
        this.pdfViewer.currentScaleValue = this.currentScale;
        this.renderWaterMarkDiv();
      }
    },
    ScreenShot() {
      this.kScreenShot.startScreenShot();
    },
    Recognize(e) {
      let param = new FormData(); // 创建form对象
      param.append("image", e.split(",")[1]); // 添加form表单中其他数据
      this.axios({
        //url: "http://127.0.0.1:5000",
        url: "http://paddle.zhongzhilian.com.cn/",
        method: "post",
        data: param,
      }).then((res) => {
        let { href } = this.$router.resolve({ path: '/HelloWorld', query: {result: res.data} })
        window.open(href, '_blank')

        // var blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
        // const a = document.createElement("a");
        // a.href = URL.createObjectURL(blob);
        // a.download = "识别结果.xls"; // 这里填保存成的文件名
        // a.click();

        // var newwin = window.open("", "", ""); //打开一个窗口并赋给变量newwin。

        // newwin.document.write(
        //   res.data.replace("<table>", '<table border="2">')
        // ); //向这个打开的窗口中写入代码code，这样就实现了运行代码功能。

        // newwin.document.close();
      });
    },
    renderWaterMarkDiv() {
      // var coverEle = document.getElementsByClassName("cover");
      // for (var i = 0, len = coverEle.length; i < len; i++) {
      //   coverEle[i].style.visibility = "visible";
      //   coverEle[i].style.width =
      //     coverEle[i].parentNode.children[0].style.width;
      //   coverEle[i].style.height = coverEle[i].parentNode.style.height;
      //   coverEle[i].style.fontSize =
      //     parseFloat(coverEle[i].parentNode.style.height.replace("px", "")) /
      //       5 +
      //     "px";
      // }
    },
  },
};
</script>
<style>
#viewerContainer {
  overflow: auto;
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>